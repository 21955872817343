// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-basic-tsx": () => import("./../../../src/templates/basic.tsx" /* webpackChunkName: "component---src-templates-basic-tsx" */),
  "component---src-templates-organization-tsx": () => import("./../../../src/templates/organization.tsx" /* webpackChunkName: "component---src-templates-organization-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

